const {
    createTheme,
    ThemeProvider
} = require("@mui/material");

export const theme = createTheme({
    palette: {
        primary: {
            main: '#3b83f6',
            light: '#4ba1ff',
            dark: '#395ecf',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#683ab7',
            light: '#9675cd',
            dark: '#522da8',
            contrastText: '#ffffff'
        },
    },
    typography: {
        body1: {
            fontSize: "14px", // Mengubah body1 menjadi 14px
        },
        h1: {
            fontSize: "84px", // Skala proporsional untuk h1
        },
        h2: {
            fontSize: "52px", // Skala proporsional untuk h2
        },
        h3: {
            fontSize: "42px",
        },
        h4: {
            fontSize: "30px",
        },
        h5: {
            fontSize: "21px",
        },
        h6: {
            fontSize: "18px",
        },
        body2: {
            fontSize: "12px",
        },
        caption: {
            fontSize: "10px",
        },
        button: {
            fontSize: "12px",
        },
        overline: {
            fontSize: "9px",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 'bold'
                }
            },
        }
    },
});

const LDTheme = (props) => {
    return (
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    )
}

export default LDTheme;